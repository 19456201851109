<template>
  <div class="creative-briefing">
    <div
      class="scroll-to-top"
      @click="handleScrollToTop"
    >
      <Transition name="slide">
        <feather-icon
          v-if="isScrollToTopButtonShow"
          class="arrow-up-circle-icon"
          icon="ArrowUpCircleIcon"
          size="30"
          stroke="white"
          fill="#7367f0"
        />
      </Transition>
    </div>
    <div class="header box">
      <div class="header-wrapper">
        <div class="header-text-wrapper">
          <b-img
            class="heroleads-logo"
            :src="require('@/assets/images/logo/heroleads-logo.png')"
            alt="Heroleads Logo"
          />
          <div class="header-text">
            Creative Briefs Form
          </div>
        </div>
        <div class="header-lang">
          <b-dropdown
            variant="none"
            toggle-class="dropdown-lang"
            no-caret
          >
            <template #button-content>
              <b-img
                class="flag"
                :src="require(`@/assets/images/icons/${currentLanguage === 'th' ? 'thailand.svg' : 'united-kingdom.svg'}`)"
                alt="Heroleads Logo"
              />
              <span class="current-lang">
                {{ currentLanguage === 'th' ? 'Thai' : 'English' }}
              </span>
              <feather-icon
                class="chevron-down-icon"
                icon="ChevronDownIcon"
                size="20"
              />
            </template>
            <b-dropdown-item
              v-for="language in languages"
              :key="language"
              @click="currentLanguage = language"
            >
              {{ language === 'th' ? 'Thai' : 'English' }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div class="history-control">
        <template v-if="!isFormLoading">
          <div class="history-control-item export">
            <HeroVueSelect
              id="last-edited-at"
              v-model="lastEditedAt"
              :label="questionList ? questionList[`${currentLanguage}_last_edited_at`] : ''"
              :options="historyOptions"
              :clearable="false"
              class="history-control-item"
              @input="getCreativeBriefing"
            />
            <HeroButtonAction
              type="button"
              variant="outline-primary"
              :disabled="isFormLoading"
              :is-loading="isFormExporting"
              @click="exportCreativeBriefing"
            >
              Export
            </HeroButtonAction>
          </div>
          <HeroInputText
            id="last-edited-by"
            v-model="lastEditedBy"
            :label="questionList ? questionList[`${currentLanguage}_last_edited_by`] : ''"
            readonly
            disabled
            class="history-control-item"
          />
        </template>
        <template v-else>
          <div v-for="index in 2" :key="index" class="history-control-item">
            <b-skeleton animation="wave" width="55%" />
            <b-skeleton type="input" />
          </div>
        </template>
      </div>
    </div>
    <div
      ref="userInfo"
      class="user-info form box"
    >
      <div class="form-title">
        User Information
      </div>
      <div class="user-info-control">
        <template v-if="!isFormLoading">
          <validation-provider
            #default="{ errors }"
            ref="fullName"
            name="Full Name"
            rules="required"
            tag="div"
            class="user-info-item"
          >
            <HeroInputText
              id="full-name"
              v-model="userInformation.fullName"
              :label="questionList ? questionList[`${currentLanguage}_full_name`] : ''"
              required
              :readonly="isFormDisabled || isAuthenticated"
              :disabled="isFormDisabled || isAuthenticated"
              :state="errors.length > 0 ? false : null"
              :invalid-feedback="errors[0]"
            />
          </validation-provider>
          <HeroInputText
            id="campaign-name"
            v-model="userInformation.campaignName"
            :label="questionList ? questionList[`${currentLanguage}_campaign_name`] : ''"
            readonly
            disabled
            class="user-info-item"
          />
        </template>
        <template v-else>
          <div v-for="index in 2" :key="index" class="user-info-item">
            <b-skeleton animation="wave" width="55%" />
            <b-skeleton type="input" />
          </div>
        </template>
      </div>
    </div>
    <validation-observer
      ref="mainForm"
      tag="form"
      class="form"
      @submit.prevent="handleSubmit"
    >
      <div class="box">
        <div class="form-title">
          <template v-if="!isFormLoading">
            {{ questionList ? questionList.all_questions[currentPart].title : '' }}
          </template>
          <template v-else>
            <b-skeleton animation="wave" width="30%" height="1.5rem" />
          </template>
        </div>
        <div
          v-if="questionList && !isFormLoading"
          class="v-stack"
        >
          <template v-for="question in questionList.all_questions[currentPart].questions">
            <validation-provider
              v-if="question.type !== 'list'"
              #default="{ errors }"
              :key="question.key"
              :vid="question.key"
              :name="question.en_label"
              :rules="question.rules.join('|')"
            >
              <component
                :is="getComponent(question.type)"
                :id="question.key"
                v-model="question.answer"
                :label="question[`${currentLanguage}_label`]"
                :tag-line="question[`${currentLanguage}_tagLine`]"
                v-bind="{
                  ...question.type === 'select' && {
                    options: question.options,
                    clearable: !question.rules.includes('required'),
                  },
                }"
                :state="errors.length > 0 ? false : null"
                :invalid-feedback="errors[0]"
                :required="question.rules.includes('required')"
                :disabled="isFormDisabled"
                :class="`custom-form-${question.type} mb-0`"
              />
            </validation-provider>
            <component
              :is="getComponent(question.type)"
              v-else
              :id="question.key"
              :key="question.key"
              v-model="question.answer"
              :label="question[`${currentLanguage}_label`]"
              :tag-line="question[`${currentLanguage}_tagLine`]"
              :limit="question.limit"
              :components="question.components"
              :required="question.rules.includes('required')"
              :disabled="isFormDisabled"
              class="mb-0"
            />
          </template>
        </div>
        <div v-else class="v-stack">
          <div v-for="index in 4" :key="index">
            <b-skeleton animation="wave" width="55%" />
            <b-skeleton animation="wave" width="85%" />
            <b-skeleton type="input" />
          </div>
        </div>
      </div>
      <div
        v-if="questionList && !isFormLoading"
        class="action"
      >
        <div class="part-control">
          <HeroButtonAction
            v-if="currentPart > 0"
            type="button"
            variant="outline-primary"
            :disabled="isFormSubmitting"
            @click="currentPart -= 1"
          >
            Back
          </HeroButtonAction>
          <HeroButtonAction
            v-if="currentPart < questionList.all_questions.length - 1"
            type="button"
            variant="outline-primary"
            :disabled="isFormSubmitting"
            @click="handleNextStep"
          >
            Next
          </HeroButtonAction>
        </div>
        <HeroButtonAction
          v-if="currentPart === questionList.all_questions.length - 1 && !isFormDisabled"
          type="submit"
          variant="primary"
          :is-loading="isFormSubmitting"
        >
          Submit
        </HeroButtonAction>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroTextarea from '@/views/components/form/inputs/HeroTextarea.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroItemList from '@/views/components/form/HeroItemList.vue'
import {
  required,
  url,
} from '@validations'
import { extend } from 'vee-validate'
import axiosInstance from '@/libs/axiosInstance'
import Constant from '@/utils/Constant'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'
import moment from 'moment'

extend('phone_number', {
  message: 'The {_field_} is an invalid format',
  validate(value) {
    return /^[+]?[0-9]+/g.test(value)
  },
})

export default {
  components: {
    HeroVueSelect,
    HeroButtonAction,
    HeroTextarea,
    HeroInputText,
    HeroItemList,
  },
  data() {
    return {
      required,
      url,
      isFormLoading: false,
      isFormSubmitting: false,
      isFormExporting: false,
      isScrollToTopButtonShow: false,
      currentPart: 0,
      currentLanguage: 'th',
      languages: ['th', 'en'],
      lastEditedBy: '',
      lastEditedAt: '',
      historyOptions: [],
      creativeBriefingName: '',
      editable: false,
      userInformation: {
        fullName: '',
        campaignName: '',
      },
      questionList: null,
      prevRoute: '',
    }
  },
  computed: {
    isFormDisabled() {
      return this.historyOptions.findIndex(history => history.value === this.lastEditedAt) !== 0 || !this.editable
    },

    opportunityId() {
      return this.$route.params.oppId
    },

    creativeBriefingId() {
      return this.$route.params.id
    },

    isAuthenticated() {
      return this.$store.getters['heroAiAuthentications/isAuthenticated']
    },
  },
  watch: {
    currentPart() {
      this.handleScrollToTop()
    },
  },
  mounted() {
    this.initialize()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    async initialize() {
      window.addEventListener('scroll', this.handleScroll)

      this.isFormLoading = true
      this.userInformation.fullName = this.$store.getters['heroAiAuthentications/fullName'] || ''
      await this.getCreativeBriefingQuestions()
      await this.getCreativeBriefingHistory()
      await this.getCreativeBriefing()
      this.isFormLoading = false
    },

    async getCreativeBriefingQuestions() {
      try {
        const { data } = await axiosInstance.get(Constant.apiURL.campaignCreativeBrief.questions)
        this.questionList = { ...data }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }
    },

    async getCreativeBriefingHistory() {
      try {
        const { data: { data: histories } } = await axiosInstance.get(Constant.apiURL.campaignCreativeBrief.history(this.creativeBriefingId))
        this.historyOptions = histories.map(history => ({
          text: moment(history.created_at).add(7, 'hours').format('YYYY-MM-DD hh:mm:ss'),
          value: history.created_at,
        }))

        const { created_at: createdAt } = this.$route.query

        if (createdAt) {
          const historyOptionByQueryString = this.historyOptions.find(historyOption => historyOption.text === createdAt)
          if (historyOptionByQueryString) {
            this.lastEditedAt = historyOptionByQueryString.value
            return
          }
        }

        this.lastEditedAt = this.historyOptions[0].value
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }
    },

    async getCreativeBriefing() {
      try {
        const apiURL = `${Constant.apiURL.campaignCreativeBrief.show(this.opportunityId, this.creativeBriefingId)}?created_at=${this.lastEditedAt}`
        const { data } = await axiosInstance.get(apiURL)

        this.editable = !!data.data.editable
        this.currentLanguage = data.data.language === 'Thai' ? 'th' : 'en'
        this.lastEditedBy = data.data.created_by
        this.userInformation.campaignName = data.data.campaign_name || ''
        this.creativeBriefingName = data.data.name

        this.questionList.all_questions = this.questionList.all_questions.map(allQuestion => ({
          ...allQuestion,
          questions: allQuestion.questions.map(question => ({
            ...question,
            answer: data.data[question.key] || (question.type === 'list' ? [] : ''),
          })),
        }))
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }
    },

    async exportCreativeBriefing() {
      try {
        this.isFormExporting = true
        const apiURL = `${Constant.apiURL.campaignCreativeBrief.export(this.creativeBriefingId)}?created_at=${this.lastEditedAt}`
        const { data } = await axiosInstance.get(apiURL, {
          responseType: 'blob',
        })

        const href = URL.createObjectURL(data)

        const link = document.createElement('a')
        link.href = href

        const creativeBriefingNameFormatted = this.creativeBriefingName.replace(/\s/g, '_')
        const dateTimeFormatted = moment(this.lastEditedAt).add(7, 'hours').format('YYYYMMDD_hhmmss')
        const fileName = `${creativeBriefingNameFormatted}_${dateTimeFormatted}`

        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()

        document.body.removeChild(link)
        URL.revokeObjectURL(href)
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.isFormExporting = false
      }
    },

    getComponent(type) {
      const components = {
        input: HeroInputText,
        textarea: HeroTextarea,
        select: HeroVueSelect,
        list: HeroItemList,
      }

      return components[type]
    },

    handleScrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },

    handleScroll() {
      if (this.$refs.userInfo) {
        const { offsetTop, offsetHeight } = this.$refs.userInfo
        this.isScrollToTopButtonShow = window.scrollY > (offsetTop + offsetHeight)
      }
    },

    async handleNextStep() {
      await this.$refs.fullName.validate()
      const isMainFormValid = this.isFormDisabled ? true : await this.$refs.mainForm.validate()
      if (isMainFormValid) {
        this.$refs.mainForm.reset()
        this.currentPart += 1
      }
    },

    async handleSubmit() {
      try {
        this.isFormSubmitting = true
        const isFullNameValid = (await this.$refs.fullName.validate()).valid
        const isMainFormValid = await this.$refs.mainForm.validate()
        if (isMainFormValid && isFullNameValid) {
          let payload = {
            opportunity_id: this.opportunityId,
            language: this.currentLanguage === 'th' ? 'Thai' : 'English',
            created_by: this.userInformation.fullName,
          }

          this.questionList.all_questions.forEach(allQuestion => {
            allQuestion.questions.forEach(question => {
              payload = {
                ...payload,
                [question.key]: question.answer,
              }
            })
          })

          await axiosInstance.put(Constant.apiURL.campaignCreativeBrief.update(this.creativeBriefingId), payload)
          await this.$swal({ ...SweetAlert.success, text: 'Update Successfully' })
          await this.$router.push(`/creative-briefing/${this.opportunityId}/${this.creativeBriefingId}/thank-you`)
        }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.isFormSubmitting = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.slide-enter-active,
.slide-leave-active {
  transition: all .5s ease-in-out;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}

.v-stack {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.creative-briefing {
  position: relative;
  max-width: 768px;
  margin: auto;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  .scroll-to-top {
    position: absolute;
    right: 1rem;
    bottom: 85px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    .arrow-up-circle-icon {
      position: fixed;
      bottom: 85px;
      z-index: 1;
      > ::v-deep circle {
        stroke: #7367f0;
      }
    }
  }
  ::v-deep .form-group {
    label {
      span {
        font-size: 0.875rem !important;
        @media(min-width: 768px) {
          font-size: 1rem !important;
        }
      }
    }
    .tag-line {
      font-size: 0.75rem !important;
      @media(min-width: 768px) {
        font-size: 0.875rem !important;
      }
    }
  }
  .box {
    background: white;
    padding: 1rem;
    border-radius: 6px;
    border: 1px solid #D8D6DE;
  }
  > .header {
    > .header-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.75rem;
      .header-text-wrapper {
        display: flex;
        align-items: center;
        column-gap: 8px;
        .heroleads-logo {
          width: 25px;
          height: 25px;
          @media(min-width: 768px) {
            width: 40px;
            height: 40px;
          }
        }
        .header-text {
          font-size: 1.125rem;
          font-weight: 500;
          @media(min-width: 768px) {
            font-size: 2rem;
          }
        }
      }
      .header-lang {
        ::v-deep .dropdown-lang {
          display: flex;
          align-items: center;
          column-gap: 6px;
          padding: 0;
          .chevron-down-icon {
            transition: transform .25s ease-in-out;
          }
          &[aria-expanded="true"] {
            .chevron-down-icon {
              transform: rotateX(180deg);
            }
          }
          &[aria-expanded="false"] {
            .chevron-down-icon {
              transform: rotateX(0);
            }
          }
          .flag {
            width: 24px;
            height: 24px;
          }
          .current-lang {
            font-size: 0.75rem;
            font-weight: 500;
            @media(min-width: 768px) {
              font-size: 0.875rem;
            }
          }
        }
      }
    }
    > .history-control {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      row-gap: 1rem;
      @media(min-width: 768px) {
        flex-direction: row;
        column-gap: 6px;
      }
      .history-control-item {
        flex: 1 0 0;
        width: 100%;
      }
      .export {
        display: flex;
        align-items: flex-end;
        column-gap: 3px;
      }
      .form-group {
        margin-bottom: 0;
      }
    }
  }

  > .user-info {
    > .user-info-control {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      row-gap: 1rem;
      @media(min-width: 768px) {
        flex-direction: row;
        column-gap: 6px;
      }
      .user-info-item {
        flex: 1 0 0;
        width: 100%;
      }
      .form-group {
        margin-bottom: 0;
      }
    }
  }
  > .form {
    margin-top: 1rem;
    .form-group {
      &.custom-form {
        &-input {
          ::v-deep .input-group {
            @media(min-width: 768px) {
              width: 50%;
            }
          }
        }
        &-select {
          ::v-deep .v-select {
            @media(min-width: 768px) {
              width: 50%;
            }
          }
        }
      }
    }
    .form-title {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 1rem;
      @media(min-width: 768px) {
        font-size: 1.5rem;
      }
    }
    > .action {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1rem;
      padding: 0 1rem 0 1rem;
      @media(min-width: 768px) {
        padding: 0;
      }
      .part-control {
        display: flex;
        align-items: center;
        column-gap: 6px;
      }
    }
  }
  .btn {
    width: auto;
    margin: 0;
  }
}
</style>
